<style>
@media (max-width: 1024px) {
  .c-sm-spacer {
    margin-top: 1rem;
  }
}
</style>


<script>
import store from "@/state/store";

import {loadStripe} from '@stripe/stripe-js';
import {HalfCircleSpinner} from "epic-spinners";

import {get_acsrf_token} from "@/methods";
import CopyableText from "@/components/CopyableText";
import {PaymentGateway, PaymentMethodType} from "@/enums";

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CopyableText,
    HalfCircleSpinner
  },
  methods: {
    async retrievePaymentGateways() {
      try {
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/account/payment-gateways`, {
          method: 'GET',
          credentials: 'include'
        });
        if(response.ok) {
          let data = await response.json();
          this.paymentGateways = data.payment_gateways;
        } else {
          this.error = true;
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
      }
    },
    async processPaymentGatewayRequirements(data) {
      switch(data.gateway) {
        case PaymentGateway.STRIPE: {
          let result = await this.stripeObject.confirmCardPayment(
              data.client_secret,
              {
                payment_method: data.payment_method_id
              }
          );
          if(result.error) {
            this.$swal({
              icon: 'error',
              text: this.$t('billing.payment.confirm.error')
            });
            this.processing = false;
          } else if(result.paymentIntent.status === 'succeeded') {
            window.location.href = '/';
            this.$swal({
              icon: 'success'
            });
          }
          break;
        }
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    async approvePendingPayment() {
      this.processing = true;

      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token
        };

        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/account/payment-gateway/approve`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(!response.ok) {
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.processing = false;
        this.$swal({
          icon: 'error',
          text: this.$t('billing.payment.confirm.error')
        });
      }
    }
  },
  created() {
    try {
      this.$socket.client.on('billing:gateway:approve', this.processPaymentGatewayRequirements);
    } catch (e) {
      // nothing
    }
  },
  async mounted() {
    await this.retrievePaymentGateways();
    if (!this.error) {
      this.stripeObject = await loadStripe(this.paymentGateways.stripe.pk);
      this.stripeElements = this.stripeObject.elements();
      this.ready = true;
    }
  },
  destroyed() {
    try {
      this.$socket.client.off('billing:gateway:approve', this.processPaymentGatewayRequirements);
    } catch (e) {
      // nothing
    }
  },
  data() {
    return {
      PaymentGateway:PaymentGateway,
      PaymentMethodType:PaymentMethodType,
      ready: false,
      error: false,
      processing: false,
      paymentGateways: null
    }
  }
};
</script>

<template>
  <div>
    <template v-if="ready">
      <div class="row">
        <div class="col justify-content-center">
          <div  class="info-component text-center align-middle mt-auto mb-auto">
            <div class="info-component-space">
              <i class="fad fa-info-circle info-component-icon"></i>
            </div>
            <div class="row" style="margin-top: 20px;">
              <div class="col-lg-12">
                <h3>{{$t('billing.payment.approve.title')}}</h3>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col">
                <button class="btn btn-primary btn-lg text-uppercase" :disabled="processing" v-on:click="approvePendingPayment()">
                  <h5 class="mb-0">
                    <half-circle-spinner
                        v-if="processing"
                        :animation-duration="900"
                        :size="18"
                        class="align-middle d-inline-block"
                    />
                    {{$t('billing.payment.approve.button')}}
                  </h5>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div style="margin: auto; width: 80px;">
        <half-circle-spinner
            :animation-duration="2000"
            :size="80"
            color="white"
            class="align-middle"
        />
      </div>
    </template>
  </div>
</template>
